import React, { useEffect, useState } from "react";
import { Col, Container, Row, Card, CardHeader, CardBody } from "reactstrap";
import { Breadcrumbs, H5, P } from "../AbstractElements";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import api from "../api";
import DataTable from "react-data-table-component";
import {
  Badge,
  Button,
  Checkbox,
  Group,
  LoadingOverlay,
  Modal,
  Select,
  TextInput,
} from "@mantine/core";
import { Edit, PlusCircle, Trash2 } from "react-feather";
import { getStatusColor } from "../utils/functions";
import { IconInputSearch, IconNotification } from "@tabler/icons-react";
import moment from "moment";
import { useDisclosure } from "@mantine/hooks";
import { DatePickerInput } from "@mantine/dates";

const Memberships = () => {
  const [memberships, setMemberships] = useState([]);
  const [membershipTypes, setMembershipTypes] = useState([]);
  const [students, setStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [newOpened, newHandlers] = useDisclosure(false);
  const [editOpened, editHandlers] = useDisclosure(false);
  const [deleteOpened, deleteHandlers] = useDisclosure(false);
  const [sendReminderOpened, sendReminderHandlers] = useDisclosure(false);
  const [chosenStudents, setChosenStudents] = useState([]);
  const [newMembership, setNewMembership] = useState(null);
  const [selected, setSelected] = useState(null);
  const [expiryDate, setExpiryDate] = useState();

  const columns = [
    {
      name: "Student Name",
      selector: (row) => `${row.student?.firstName} ${row.student?.lastName}`,
    },
    {
      name: "Membership status",
      selector: (row) => (
        <Badge color={getStatusColor(row.status)}>{row.status}</Badge>
      ),
      sortable: true,
      sortFunction: (rowA, rowB) => {
        // Define custom sort order if needed
        const statusOrder = {
          Active: 1,
          Pending: 2,
          Expired: 3,
          // Add other status values as needed
        };

        // Compare based on the defined order
        return (
          (statusOrder[rowA?.status] || 999) -
          (statusOrder[rowB?.status] || 999)
        );
      },
    },
    {
      name: "Membership Type",
      selector: (row) => (
        <P>
          {row?.membershipType?.duration?.number}{" "}
          {row?.membershipType?.duration?.type}
        </P>
      ),
    },
    {
      name: "Expiry date",
      selector: (row) => moment(row.expiryDate).format("DD MMMM YYYY"),
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const dateA = new Date(rowA.expiryDate);
        const dateB = new Date(rowB.expiryDate);

        return dateA - dateB;
      },
    },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          <Button
            size="compact-sm"
            variant="outline"
            className="mx-2"
            onClick={() => openEditModal(row)}
          >
            <Edit size={13} />
          </Button>
          <Button
            size="compact-sm"
            variant="outline"
            color="red"
            onClick={() => openDeleteModal(row)}
          >
            <Trash2 size={13} />
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const mt = membershipTypes?.find(
      (mt) => mt?._id === newMembership?.membershipType
    );
    if (mt) {
      const newExpiry = moment().add(mt.duration.number, mt.duration.type);
      setExpiryDate(newExpiry);
    }
  }, [newMembership]);

  useEffect(() => {
    const mt = membershipTypes?.find(
      (mt) => mt?._id === selected?.membershipType
    );
    if (mt) {
      const newExpiry = moment(selected?.beginDate).add(
        mt?.duration?.number,
        mt?.duration?.type
      );
      setSelected({ ...selected, expiryDate: newExpiry });
    }
  }, [selected?.membershipType, selected?.beginDate]);

  useEffect(() => {
    const mt = membershipTypes?.find(
      (mt) => mt?._id === newMembership?.membershipType
    );
    if (mt) {
      const newExpiry = moment().add(mt?.duration?.number, mt?.duration?.type);
      setExpiryDate(newExpiry);
    }
  }, [newMembership]);

  const queryClient = useQueryClient();

  const { isFetching: loadingStudents } = useQuery({
    queryKey: ["getStudents"],
    queryFn: async () => {
      try {
        const response = await api.post("/student/list");
        setStudents(response.data);
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
  });

  const { isFetching: loadingMTs } = useQuery({
    queryKey: ["getMembershipTypes"],
    queryFn: async () => {
      try {
        const response = await api.post("/membership-type/list");
        setMembershipTypes(response.data);
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
  });

  const closeModal = () => {
    newHandlers.close();
    editHandlers.close();
    deleteHandlers.close();
    setSelected(null);
  };

  const openEditModal = (membership) => {
    setSelected(membership);
    editHandlers.open();
  };

  const openDeleteModal = (membership) => {
    setSelected(membership);
    deleteHandlers.open();
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredMemberships = memberships.filter((membership) => {
    const fullName =
      `${membership?.student?.firstName} ${membership?.student?.lastName}`.toLowerCase();
    const searchTermLower = searchTerm.toLowerCase();

    return fullName.includes(searchTermLower);
  });

  const { isFetching } = useQuery({
    queryKey: ["getMemberships"],
    queryFn: async () => {
      try {
        const response = await api.post("/membership/list");
        setMemberships(response.data);
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
  });

  const createMembership = useMutation({
    mutationFn: async (data) => {
      try {
        const response = await api.post(`membership/new`, data);
        return response.data;
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["getMemberships"] });
      closeModal();
      toast.success("Membership added successully");
    },
  });

  const editMembership = useMutation({
    mutationFn: async (data) => {
      try {
        const response = await api.post(
          `membership/update/${selected?._id}`,
          data
        );
        return response.data;
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["getMemberships"] });
      closeModal();
      toast.success("Membership edited successully");
    },
  });

  const deleteMembership = useMutation({
    mutationFn: async () => {
      try {
        const response = await api.post(`membership/delete/${selected?._id}`);
        return response.data;
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["getMemberships"] });
      closeModal();
      toast.success("Membership deleted successully");
    },
  });

  const sendNotification = useMutation({
    mutationFn: async (data) => {
      try {
        const response = await api.post(`membership/send-reminders`, data);
        return response.data;
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    onSuccess: async () => {
      sendReminderHandlers.close();
      setChosenStudents([]);
      queryClient.invalidateQueries({ queryKey: ["getMemberships"] });
      toast.success("Reminders sent successfully");
    },
  });

  const handleSendNotification = () => {
    sendNotification.mutate({
      students: chosenStudents,
    });
  };

  return (
    <>
      <Breadcrumbs mainTitle="Manage memberships" title="Memberships" />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Group justify="space-between">
                  <TextInput
                    type="search"
                    className="w-1/3"
                    leftSection={<IconInputSearch />}
                    placeholder="Type to search for a student by name, email, phone or rank..."
                    onChange={handleSearch}
                  />
                  <Group>
                    <Button
                      variant="outline"
                      color="primary"
                      leftSection={<IconNotification size={15} />}
                      onClick={() => sendReminderHandlers.open()}
                    >
                      Send reminder by notification
                    </Button>
                    <Button
                      variant="outline"
                      color="primary"
                      leftSection={<PlusCircle size={15} />}
                      onClick={() => newHandlers.open()}
                    >
                      Create membership
                    </Button>
                  </Group>
                </Group>
              </CardHeader>
              <CardBody>
                <DataTable
                  progressPending={isFetching}
                  pagination
                  columns={columns}
                  data={filteredMemberships}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal title="New membership" opened={newOpened} onClose={closeModal}>
        <Select
          label="Student"
          searchable
          placeholder="Student"
          data={students.map((student) => ({
            value: student?._id,
            label: `${student?.firstName} ${student?.lastName} - ${
              student?.rank?.rankInfo
                ? student?.rank?.rankInfo?.name
                : "No rank"
            }`,
          }))}
          onChange={(val) =>
            setNewMembership({ ...newMembership, student: val })
          }
          value={newMembership?.student}
        />
        <Select
          label="Membership Type"
          placeholder="Type"
          searchable
          data={membershipTypes.map((type) => ({
            value: type._id,
            label: `${type.description} - ${type.duration.number} ${type.duration.type}`,
          }))}
          onChange={(val) =>
            setNewMembership({ ...newMembership, membershipType: val })
          }
          value={newMembership?.membershipType}
        />
        <p>
          Expiry date :{" "}
          <strong>{expiryDate ? expiryDate.format("DD MMM YYYY") : "-"}</strong>
        </p>
        <Group justify="flex-end" className="mt-5">
          <Button variant="outline" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            onClick={() => createMembership.mutate(newMembership)}
            loading={createMembership.isPending}
          >
            Save
          </Button>
        </Group>
      </Modal>
      <Modal title="Edit membership" opened={editOpened} onClose={closeModal}>
        <Select
          label="Student"
          searchable
          placeholder="Student"
          data={students.map((student) => ({
            value: student?._id,
            label: `${student?.firstName} ${student?.lastName} - ${
              student?.rank?.rankInfo
                ? student?.rank?.rankInfo?.name
                : "No rank"
            }`,
          }))}
          onChange={(val) => setSelected({ ...selected, student: val })}
          value={selected?.student?._id ?? selected?.student}
        />
        <Select
          label="Membership Type"
          placeholder="Type"
          searchable
          data={membershipTypes.map((type) => ({
            value: type._id,
            label: `${type.description} - ${type.duration.number} ${type.duration.type}`,
          }))}
          onChange={(val) => setSelected({ ...selected, membershipType: val })}
          value={selected?.membershipType?._id ?? selected?.membershipType}
        />
        <DatePickerInput
          label="Begin date"
          value={moment(selected?.beginDate)}
          onChange={(val) => setSelected({ ...selected, beginDate: val })}
        />
        <DatePickerInput
          label="Expiry date"
          value={moment(selected?.expiryDate)}
          onChange={(val) => setSelected({ ...selected, expiryDate: val })}
        />
        <Select
          label="Status"
          data={["Pending", "Active", "Inactive", "Overdue"]}
          value={selected?.status}
          onChange={(val) => setSelected({ ...selected, status: val })}
        />
        <Group justify="flex-end" className="mt-5">
          <Button variant="outline" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            onClick={() => editMembership.mutate(selected)}
            loading={editMembership.isPending}
          >
            Save
          </Button>
        </Group>
      </Modal>
      <Modal opened={deleteOpened} onClose={deleteHandlers.close}>
        <p>Are you sure you want to delete this membership?</p>
        <Group justify="flex-end" className="mt-5">
          <Button variant="outline" onClick={deleteHandlers.close}>
            Cancel
          </Button>
          <Button
            color="red"
            onClick={() => deleteMembership.mutate()}
            loading={deleteMembership.isPending}
          >
            Delete
          </Button>
        </Group>
      </Modal>
      <Modal
        title="Send a notification reminder "
        opened={sendReminderOpened}
        onClose={() => {
          sendReminderHandlers.close();
          setChosenStudents([]);
        }}
      >
        <LoadingOverlay visible={sendNotification.isPending} />
        <Checkbox.Group
          defaultValue={[]}
          value={chosenStudents}
          onChange={(value) => setChosenStudents(value)}
          label="Please choose the students that you wish to send a reminder to :"
          withAsterisk
        >
          <Group mt="xs">
            {memberships
              ?.filter((el) => el?.status == "Overdue")
              .map((membership, key) => (
                <Checkbox
                  key={key}
                  value={membership?.student?._id}
                  label={`${membership?.student?.firstName} ${membership?.student?.lastName}`}
                />
              ))}
          </Group>
        </Checkbox.Group>
        <Group justify="flex-end" className="mt-5">
          <Button
            variant="outline"
            onClick={() => {
              sendReminderHandlers.close();
              setChosenStudents([]);
            }}
          >
            Cancel
          </Button>
          <Button variant="filled" onClick={handleSendNotification}>
            Send
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default Memberships;
