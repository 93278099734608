import React, { useState } from "react";
import { Breadcrumbs, H1, H3, H5, H6, P } from "../AbstractElements";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import {
  ActionIcon,
  Badge,
  Button,
  Group,
  LoadingOverlay,
  Card as MantineCard,
  Menu,
  Modal,
  MultiSelect,
  Popover,
  rem,
  Select,
  SimpleGrid,
  Switch,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { Edit, PlusCircle } from "react-feather";
import {
  IconArchive,
  IconDots,
  IconNotification,
  IconTrash,
} from "@tabler/icons-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import api from "../api";
import { useDisclosure } from "@mantine/hooks";

const Schedule = () => {
  const [sessions, setSessions] = useState([]);
  const [selectedDay, setSelectedDay] = useState(0);
  const [createModalOpen, createModalHandlers] = useDisclosure(false);
  const [editModalOpen, editModalHandlers] = useDisclosure(false);
  const [deleteModalOpen, deleteModalHandlers] = useDisclosure(false);
  const [archiveModalOpen, archiveModalHandlers] = useDisclosure(false);
  const [selectedSession, setSelectedSession] = useState();
  const [coaches, setCoaches] = useState();
  const [showArchived, setShowArchived] = useState(false);

  const queryClient = useQueryClient();

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const { isFetching } = useQuery({
    queryKey: ["getSessions"],
    queryFn: async () => {
      try {
        const response = await api.get("/session/list");
        setSessions(response.data);
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
  });

  const { isFetching: isLoading } = useQuery({
    queryKey: ["getCoaches"],
    queryFn: async () => {
      try {
        const response = await api.get("/coach/list");
        setCoaches(response.data);
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
  });

  const createSession = useMutation({
    mutationFn: async (data) => {
      try {
        const response = await api.post("/session/new", data);
        return response.data;
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ["getSessions"] });
      toast.success("Session created successully");
      createModalHandlers.close();
    },
  });

  const editSession = useMutation({
    mutationFn: async (data) => {
      try {
        const response = await api.post(`/session/update/${data?._id}`, data);
        return response.data;
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getSessions"] });
      toast.success("Session edited successully");
      editModalHandlers.close();
    },
  });

  const deleteSession = useMutation({
    mutationFn: async (data) => {
      try {
        const response = await api.post(`/session/delete/${data?._id}`, data);
        return response.data;
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getSessions"] });
      toast.success("Session deleted successully");
      deleteModalHandlers.close();
    },
  });

  const archiveSession = useMutation({
    mutationFn: async (data) => {
      try {
        const response = await api.post(`/session/archive/${data?._id}`, data);
        return response.data;
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getSessions"] });
      toast.success(
        `Session ${
          selectedSession?.delete ? "un-archived" : "archived"
        } successully`
      );
      archiveModalHandlers.close();
    },
  });

  const notifyUsers = useMutation({
    mutationFn: async (data) => {
      try {
        const response = await api.post(`/notifications/new-schedule`);
        return response.data;
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    onSuccess: () => {
      toast.success(`All users have been notified`);
    },
  });

  const handleNotify = () => {
    notifyUsers.mutate();
  };

  const handleEdit = (session) => {
    setSelectedSession(session);
    editModalHandlers.open();
  };

  const handleDelete = (session) => {
    setSelectedSession(session);
    deleteModalHandlers.open();
  };

  const handleArchive = (session) => {
    setSelectedSession(session);
    archiveModalHandlers.open();
  };

  const handleChange = ({ target }) => {
    setSelectedSession({ ...selectedSession, [target.name]: target.value });
  };

  const handleSaveEdit = () => {
    editSession.mutate(selectedSession);
  };

  const handleSaveCreate = () => {
    createSession.mutate(selectedSession);
  };

  const handleSaveDelete = () => {
    deleteSession.mutate(selectedSession);
  };

  const handleSaveArchive = () => {
    archiveSession.mutate(selectedSession);
  };

  return (
    <>
      <Breadcrumbs mainTitle="Manage schedule" title="Schedule" />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Group justify="space-between">
                  <div className="flex">
                    {daysOfWeek.map((day, key) => (
                      <Button
                        className="mr-3"
                        key={key}
                        variant={selectedDay === key ? "filled" : "outline"}
                        onClick={() => setSelectedDay(key)}
                      >
                        {day}
                      </Button>
                    ))}
                  </div>
                  <div className="flex">
                    <Button
                      className="mx-1"
                      variant="outline"
                      leftSection={<PlusCircle size={15} />}
                      onClick={() => {
                        setSelectedSession({});
                        createModalHandlers.open();
                      }}
                    >
                      Add Session
                    </Button>
                    <Popover
                      width={300}
                      position="bottom"
                      withArrow
                      shadow="md"
                    >
                      <Popover.Target>
                        <Button
                          variant="outline"
                          leftSection={<IconNotification size={15} />}
                        >
                          Notify students
                        </Button>
                      </Popover.Target>
                      <Popover.Dropdown>
                        <Text size="sm">
                          Attention: This action will send a notification about
                          a schedule change to all registered users: Students
                          and Guests. Would you like to proceed ?
                        </Text>
                        <Group className="mt-1" justify="flex-end">
                          <Button
                            variant="outline"
                            onClick={handleNotify}
                            loading={notifyUsers.isPending}
                          >
                            Send notification
                          </Button>
                        </Group>
                      </Popover.Dropdown>
                    </Popover>
                  </div>
                </Group>
                <Group justify="flex-start" className="mt-3">
                  <Switch
                    value={showArchived}
                    label="Show archived sessions"
                    onChange={() => setShowArchived(!showArchived)}
                  />
                </Group>
              </CardHeader>
              <CardBody>
                <SimpleGrid cols={3}>
                  {sessions
                    ?.filter((el) => el.dayOfWeek === selectedDay)
                    .filter((el) => (showArchived ? true : !el?.deleted))
                    .length === 0 && <Text>No sessions found</Text>}
                  {sessions
                    ?.filter((el) => el.dayOfWeek === selectedDay)
                    .filter((el) => (showArchived ? true : !el?.deleted))
                    .map((session, key) => (
                      <MantineCard
                        key={key}
                        shadow="sm"
                        padding={"sm"}
                        withBorder
                      >
                        <Group justify="flex-end">
                          {session?.deleted && (
                            <Badge variant="outline" color="red">
                              Archived
                            </Badge>
                          )}
                          <Menu withinPortal position="bottom-end" shadow="sm">
                            <Menu.Target>
                              <ActionIcon variant="subtle" color="gray">
                                <IconDots
                                  style={{ width: rem(16), height: rem(16) }}
                                />
                              </ActionIcon>
                            </Menu.Target>

                            <Menu.Dropdown>
                              <Menu.Item
                                onClick={() => handleEdit(session)}
                                leftSection={
                                  <Edit
                                    style={{ width: rem(14), height: rem(14) }}
                                  />
                                }
                              >
                                Edit
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => handleArchive(session)}
                                leftSection={
                                  <IconArchive
                                    style={{ width: rem(14), height: rem(14) }}
                                  />
                                }
                                color="red"
                              >
                                {session?.deleted ? "Unarchive" : "Archive"}
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => handleDelete(session)}
                                leftSection={
                                  <IconTrash
                                    style={{ width: rem(14), height: rem(14) }}
                                  />
                                }
                                color="red"
                              >
                                Delete permanently
                              </Menu.Item>
                            </Menu.Dropdown>
                          </Menu>
                        </Group>
                        {
                          <Group justify="space-between">
                            <H3>{session?.name}</H3>
                            <span>
                              {session?.startTime} - {session?.endTime}
                            </span>
                          </Group>
                        }
                        <H6>{session?.type}</H6>
                        <P>{session?.description}</P>
                        <Group justify="flex-end">
                          <strong>Coaches :</strong>
                          {session?.defaultCoaches?.map((coachId) => (
                            <Badge variant="outline">
                              {
                                coaches?.find((el) => el?._id == coachId)
                                  ?.firstName
                              }{" "}
                              {
                                coaches?.find((el) => el?._id == coachId)
                                  ?.lastName
                              }
                            </Badge>
                          ))}
                        </Group>
                      </MantineCard>
                    ))}
                </SimpleGrid>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        centered
        opened={createModalOpen}
        title="Create session"
        onClose={createModalHandlers.toggle}
      >
        <LoadingOverlay visible={createSession.isPending} />
        <Group className="mt-2" justify="space-between">
          <p className="font-semibold">Day of week</p>
          <Select
            data={daysOfWeek.map((day, key) => {
              return { label: day, value: key.toString() };
            })}
            onChange={(val) =>
              setSelectedSession({ ...selectedSession, dayOfWeek: val })
            }
            value={selectedSession?.dayOfWeek}
          />
        </Group>
        <Group className="mt-2" justify="space-between">
          <p className="font-semibold">Session name</p>
          <TextInput
            name="name"
            onChange={handleChange}
            value={selectedSession?.name}
          />
        </Group>
        <div className="mt-2">
          <p className="font-semibold">Session description</p>
          <Textarea
            name="description"
            onChange={handleChange}
            value={selectedSession?.description}
          />
        </div>
        <Group className="mt-2" justify="space-between">
          <p className="font-semibold">Start time</p>
          <TextInput
            name="startTime"
            onChange={handleChange}
            value={selectedSession?.startTime}
          />
        </Group>
        <Group className="mt-2" justify="space-between">
          <p className="font-semibold">End time</p>
          <TextInput
            name="endTime"
            onChange={handleChange}
            value={selectedSession?.endTime}
          />
        </Group>
        <Group className="mt-2" justify="space-between">
          <p className="font-semibold">Session type</p>
          <Select
            data={[
              "NoGi Jiu-Jitsu",
              "Gi Jiu-Jitsu",
              "Wrestling",
              "Kids",
              "White belts",
              "MMA",
            ]}
            onChange={(val) =>
              setSelectedSession({ ...selectedSession, type: val })
            }
            value={selectedSession?.type}
          />
        </Group>
        <Group className="mt-2" justify="space-between">
          <p className="font-semibold">Default coach</p>
          <Select
            data={coaches?.map((coach) => {
              return {
                label: `${coach?.firstName} ${coach?.lastName}`,
                value: coach?._id,
              };
            })}
            onChange={(val) =>
              setSelectedSession({ ...selectedSession, defaultCoach: val })
            }
            value={selectedSession?.defaultCoach?._id}
          />
        </Group>
        <Group justify="flex-end" className="mt-4">
          <Button
            className="mx-2"
            variant="outline"
            onClick={createModalHandlers.close}
          >
            Cancel
          </Button>
          <Button onClick={handleSaveCreate} variant="filled">
            Save
          </Button>
        </Group>
      </Modal>
      <Modal
        centered
        opened={editModalOpen}
        title="Edit session"
        onClose={editModalHandlers.toggle}
      >
        <LoadingOverlay visible={editSession.isPending} />
        <Group className="mt-2" justify="space-between">
          <p className="font-semibold">Session name</p>
          <TextInput
            name="name"
            onChange={handleChange}
            value={selectedSession?.name}
          />
        </Group>
        <div className="mt-2">
          <p className="font-semibold">Session description</p>
          <Textarea
            name="description"
            onChange={handleChange}
            value={selectedSession?.description}
          />
        </div>
        <Group className="mt-2" justify="space-between">
          <p className="font-semibold">Start time</p>
          <TextInput
            name="startTime"
            onChange={handleChange}
            value={selectedSession?.startTime}
          />
        </Group>
        <Group className="mt-2" justify="space-between">
          <p className="font-semibold">End time</p>
          <TextInput
            name="endTime"
            onChange={handleChange}
            value={selectedSession?.endTime}
          />
        </Group>
        <Group className="mt-2" justify="space-between">
          <p className="font-semibold">Session type</p>
          <Select
            data={[
              "NoGi Jiu-Jitsu",
              "Gi Jiu-Jitsu",
              "Wrestling",
              "Kids",
              "White belts",
              "MMA",
            ]}
            onChange={(val) =>
              setSelectedSession({ ...selectedSession, type: val })
            }
            value={selectedSession?.type}
          />
        </Group>
        <Group className="mt-2" justify="space-between">
          <p className="font-semibold">Default coach</p>
          <MultiSelect
            style={{ width: "100%" }}
            data={coaches?.map((coach) => {
              return {
                label: `${coach?.firstName} ${coach?.lastName}`,
                value: coach?._id,
              };
            })}
            onChange={(val) =>
              setSelectedSession({ ...selectedSession, defaultCoaches: val })
            }
            value={selectedSession?.defaultCoaches}
          />
        </Group>
        <Group justify="flex-end" className="mt-4">
          <Button
            className="mx-2"
            variant="outline"
            onClick={editModalHandlers.close}
          >
            Cancel
          </Button>
          <Button onClick={handleSaveEdit} variant="filled">
            Save
          </Button>
        </Group>
      </Modal>
      <Modal
        centered
        opened={deleteModalOpen}
        title="Delete session"
        onClose={deleteModalHandlers.toggle}
      >
        <p>Are you sure you want to delete this session ?</p>
        <Group justify="flex-end" className="mt-3">
          <LoadingOverlay visible={deleteSession.isPending} />
          <Button
            className="mx-2"
            variant="outline"
            onClick={deleteModalHandlers.close}
          >
            Cancel
          </Button>
          <Button onClick={handleSaveDelete} color="red">
            Delete
          </Button>
        </Group>
      </Modal>
      <Modal
        centered
        opened={archiveModalOpen}
        title={`${selectedSession?.deleted ? "Unarchive" : "Archive"} session`}
        onClose={archiveModalHandlers.toggle}
      >
        <p>
          Are you sure you want to{" "}
          {selectedSession?.deleted ? "un-archive" : "archive"} this session ?
        </p>
        {selectedSession?.deleted ? (
          <p>Note : Unarchived sessions will appear in the schedule.</p>
        ) : (
          <p>
            Note : Archived sessions will not appear in the schedule anymore
          </p>
        )}
        <Group justify="flex-end" className="mt-3">
          <LoadingOverlay visible={archiveSession.isPending} />
          <Button
            className="mx-2"
            variant="outline"
            onClick={archiveModalHandlers.close}
          >
            Cancel
          </Button>
          <Button onClick={handleSaveArchive} color="red">
            {selectedSession?.deleted ? "Unarchive" : "Archive"}
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default Schedule;
